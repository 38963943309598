import { navigationState } from '@citizens/mfe-shared-state';
import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { assetURL, environment } from 'src/environments/environment';
import { catchError, forkJoin, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ViewMoreNotificationsModalComponent } from '../view-more-notifications-modal/view-more-notifications-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationService } from 'src/app/services/app.service';
import { SamlJson, SsoService } from 'src/app/services/application.service';
import jwt_decode from 'jwt-decode';
import { AlertService } from 'src/app/services/alert.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TabService } from 'src/app/services/tab.service';
import { HeaderTransmitService } from 'src/app/services/transmit.service';
import { TransmitUtils } from 'src/app/services/transmit-utils';
import { WindowComponent } from '../window/window.component';
import { Router } from '@angular/router';
import { sharedState } from '@citizens/mfe-shared-state';
@Component({
  selector: 'app-cards-header',
  templateUrl: './cards-header.component.html',
  styleUrls: ['./cards-header.component.scss'],
})
export class CardsHeaderComponent implements OnInit, AfterViewInit {
  @Input() id: string = '';
  @Input() isMobileApp = false;
  @Input() fullName: string = '';
  @Input() fullNameFromSession: string = '';
  @Input() links: any;
  // @ViewChild('notifications', {static: true}) notifications: ElementRef;
  @ViewChild('modal') tabModal!: TemplateRef<any>;
  @ViewChild('container', { read: ViewContainerRef })
  container!: ViewContainerRef;
  public credentials: any[] = [];
  public notificationData = [];
  isDropdownOpen: boolean = false;
  public selectedCompanyId: string = '';
  public notificationsList: any = [];
  public selectedNotifications: any = [];
  public totalNotifications: number = 0;
  public displaySpinner: boolean = false;
  public displayNotificationBtn: boolean = false;
  public samlUrl: string = '';
  public samlInfo: string = '';
  public tabName: string = '';
  public currentCompanyId: any;
  public universalId: string = '';
  public modalDisplayName: string = '';
  public modalIsRegistered: boolean = false;
  private legacyEmail: string = '';
  private personaDetailsExtesnionJson: {} | undefined;
  private appMappingLookup: any;

  get assetURL() {
    return assetURL;
  }

  constructor(
    private https: HttpClient,
    private modalService: NgbModal,
    private appService: ApplicationService,
    private applicationService: SsoService,
    private alertService: AlertService,
    private spinnerService: SpinnerService,
    private resolver: ComponentFactoryResolver,
    private tabService: TabService,
    private transmitService: HeaderTransmitService,
    private transmitUtils: TransmitUtils,
    private router: Router
  ) {}

  ngOnInit() {
    this.universalId = sessionStorage.getItem('universalId') ?? '';
    this.isMobileApp = sessionStorage.getItem('mobileApp') === 'true';
    this.fullName = JSON.parse(
      JSON.stringify(sessionStorage.getItem('userDisplayName'))
    );
    console.log(sessionStorage.getItem('userDisplayName'), 'seession');
    if (!this.id) {
      this.id = 'dashboard-header';
    }
    const credStore = sessionStorage.getItem('credData');

    if (credStore) {
      this.fullName = JSON.parse(credStore).userDisplayName;
      this.displaySpinner = true;
      this.credentials = JSON.parse(credStore).results ?? [];
      // TODO: Testing purpose
      // const mock =  this.credentials[0];
      // mock.accountLogin = "SURESHG_UATALL_MMGPS";
      // mock.applicationName = "accessoptima";
      // mock.applicationStatus = "ACTIVE";
      // mock.compId = "UATALL";
      // mock.lastLogin = "";
      // mock.legacyApplicationId = "MMGPS";
      // mock.legacyUserNm = "accessoptima";
      // mock.underMaintenance = false;
      // mock.userId = "SURESHG_UATALL";
      // this.credentials.push(mock);

      // let notificationsDataMock = this.notificationsData({'SSO_ID':cred?.accountLogin}).pipe(catchError(error => of(error)));

      let accessOptimaCredentials = this.credentials.filter((cred) =>
        /(accessoptima|cashflow)/.test(cred.applicationName.toLowerCase())
      );

      let getAllNotifications = accessOptimaCredentials.map((cred) =>
        this.appService
          .getNotificationsData({ SSO_ID: cred?.accountLogin })
          .pipe(catchError((error) => of(error)))
      );
      // getAllNotifications.push(notificationsDataMock);
      forkJoin(getAllNotifications).subscribe((notificationRes: any) => {
        this.displaySpinner = false;
        accessOptimaCredentials.forEach((cred, index) => {
          const count = notificationRes[index]?.data?.length || 0;
          if (!this.selectedCompanyId && count) {
            this.selectedCompanyId = `${cred.compId}-${cred.userId}`;
          }
          this.displayNotificationBtn = true;
          if (count !== 0) {
            this.notificationsList.push({
              count: count,
              compId: `${cred.compId}-${cred.userId}`,
              notifications: notificationRes[index]?.data ?? [],
            });
          }
          this.totalNotifications += count;
        });
        this.displayNotifications({
          currentTarget: { value: this.selectedCompanyId },
        } as never);
      });
    }
    sharedState
      .getApplicationDataSub('appMappingLookup')
      .subscribe((appMappingLookup: any) => {
        this.appMappingLookup = appMappingLookup;
      });
  }

  ngAfterViewInit(): void {
    this.transmitService.initializeTransmit(environment.transmitUrl);
  }

  profile() {
    navigationState.setNavigation('profile/view');
  }

  manageCredentials() {
    navigationState.setNavigation('credentials/manage');
  }

  openUniversalLogin() {
    console.log('digital butler');
    const openButlerEvent = new CustomEvent('open-butler', {
      detail: { from: 'header' },
    });
    dispatchEvent(openButlerEvent);
  }

  displayNotifications(event: any) {
    if (event?.currentTarget) {
      this.currentCompanyId = event?.currentTarget.value;
      const credNotif = this.notificationsList.find(
        (cred: any) => cred.compId === event?.currentTarget.value
      );
      if (credNotif) {
        this.selectedNotifications = credNotif.notifications;
      }
    }
  }

  viewMoreNotifications() {
    const cred = this.credentials.find(
      (cred, index) =>
        `${cred.compId}-${cred.userId}` === this.selectedCompanyId
    );
    const selectSsoId = {
      ssoId: cred.accountLogin,
      companyId: cred.compId,
      userId: cred.userId,
      applicationName: cred.applicationName,
    };
    const modalRef = this.modalService.open(
      ViewMoreNotificationsModalComponent,
      { size: 'sm', centered: true }
    );
    modalRef.componentInstance.notificationData = this.selectedNotifications;
    modalRef.componentInstance.selectedCredData = selectSsoId;
  }

  public openApplication() {
    const cred = this.credentials.find(
      (cred, index) => `${cred.compId}-${cred.userId}` === this.currentCompanyId
    );
    const selectSsoId = {
      ssoId: cred.accountLogin,
      companyId: cred.compId,
      userId: cred.userId,
      applicationName: cred.applicationName,
    };
    // const openAOEvent = new CustomEvent("open-access-optima", {
    //   detail: { from: "btl-application", ssoIdData: selectSsoId }
    // });
    // dispatchEvent(openAOEvent);
    this.login(selectSsoId);
  }

  login(item: any) {
    let userId = item.userId;
    let compId = item.companyId;
    let appName = item.applicationName;
    let displayName = this.appMappingLookup?.get(appName)?.displayName;
    let isRegistered = this.appMappingLookup?.get(appName)?.isRegistered;
    let accountLogin = item.ssoId;
    let personalDetailExtension = item.personaDetailsExtension;
    let webAppId = this.applicationService.getTransmitJourney(
      item.applicationName
    );
    console.log('chckng if AO btn event is triggere', item);
    if (personalDetailExtension === undefined) {
      this.legacyEmail = '';
    } else {
      this.legacyEmail = personalDetailExtension.legacyEmailAddress;
      this.personaDetailsExtesnionJson =
        personalDetailExtension.personaDetailsExtesnionJson;
      if (typeof this.personaDetailsExtesnionJson === 'string') {
        this.personaDetailsExtesnionJson = JSON.parse(
          personalDetailExtension.personaDetailsExtesnionJson
        );
      }
    }
    this.alertService.hide();
    this.spinnerService.clearText();
    // TODO actual login!
    this.appService.updateLastLogin(accountLogin, this.universalId);
    // Last Login Details Update to DB

    const samlInfo: any = this.applicationService.getSamlInfo(appName);
    this.samlUrl = samlInfo.samlActionUrl;
    this.tabName = appName;
    this.samlInfo = 'data'; // TODO: change it!
    const isOpen = this.tabService.isAppTabOpen(appName);

    if (isOpen) {
      // open dialog message to close tab
      this.openMessageDialog(displayName, isRegistered);
    } else {
      // open a window component
      const factory = this.resolver.resolveComponentFactory(WindowComponent);
      const preprodValue = sessionStorage.getItem('preprod');
      let journeyName = 'ping_dropoff';

      // seperate route for non SAML flow
      console.log(
        this.applicationService.getConfigAppsData(appName)?.usesHeaderInjection
      );
      console.log(this.applicationService.getConfigAppsData(appName)?.baseUrls);
      if (
        this.applicationService.getConfigAppsData(appName)?.usesHeaderInjection
      ) {
        const url = new URL(
          this.applicationService.getConfigAppsData(appName)?.baseUrls?.login
        );
        console.log(url);
        const hintValue = userId + '_' + (compId ? compId : '');
        console.log(hintValue);
        let params = new URLSearchParams();
        params.append('hint', hintValue);
        const redirectUrl = `${url}?${params.toString()}`;
        var newTab = window.open(
          this.applicationService.getConfigAppsData(appName)?.baseUrls?.logout
        );
        setTimeout(function () {
          if (newTab && !newTab.closed) {
            newTab.close();
            window.open(redirectUrl);
          }
        }, 2000);
      } else {
        // route for SAML flow
        this.transmitService
          .pingDropOffTransmitInvokePolicy(
            userId,
            compId,
            appName,
            webAppId,
            accountLogin,
            journeyName,
            this.universalId,
            this.legacyEmail,
            preprodValue,
            this.personaDetailsExtesnionJson
          )
          .then((res: any) => {
            console.log('token', res._token);
            const tokenInfo = this.getDecodedAccessToken(res._token);

            if (
              tokenInfo === undefined ||
              tokenInfo === null ||
              tokenInfo === ''
            ) {
              this.alertService.error(
                this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain')
              );
              return;
            }

            let ping_ref_id = tokenInfo['ping_ref_id'];
            let ping_login_url = tokenInfo['ping_sso_url'];

            if (
              ping_login_url === undefined ||
              ping_login_url === null ||
              ping_login_url === ''
            ) {
              this.alertService.error(
                this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain')
              );
              return;
            }

            const appTabComponent =
              this.container.createComponent(factory).instance;
            appTabComponent['appName'] = this.tabName;
            appTabComponent['SamlUrl'] = ping_login_url;

            this.tabService.saveAppComponentTab(appName, appTabComponent);
          })
          .catch((error: any) => {
            console.log('error', error);
            if (
              this.getNested(
                error,
                '_data',
                'failure_data',
                'reason',
                'data',
                'error',
                'numcode'
              ) == '3211'
            ) {
              item.disabled = true;
              this.alertService.error(
                this.transmitUtils.getErrorMessage('3211')
              );
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 0);
            } else if (
              this.getNested(
                error,
                '_data',
                'failure_data',
                'reason',
                'data',
                'error',
                'numcode'
              ) == '3220'
            ) {
              item.locked = true;
              this.alertService.error(
                this.transmitUtils.getErrorMessage('3220')
              );
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 0);
            } else {
              this.alertService.error(
                this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain')
              );
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 0);
            }
            // this.service.previousDisableState.next(this.data);
          });
      }
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  getNested(obj: any, ...args: any) {
    return args.reduce((obj: any, level: any) => obj && obj[level], obj);
  }

  openMessageDialog(displayName: any, isRegistered: any) {
    this.modalDisplayName = displayName;
    this.modalIsRegistered = isRegistered;
    this.modalService.open(this.tabModal, { size: 'sm', centered: true });
  }

  logout() {
    const userType = sessionStorage.getItem('userType');
    sessionStorage.removeItem('banner_preference');
    sessionStorage.removeItem('isSMBUser');
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace(environment.logoutUrl);
  }
}
